<template>
  <div class="list">
    <ShopNavBar title="消息" />

    <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
      finished-text="没有更多了" @load="onLoad">
      <div class="chat-item" v-for="(item, index) in list" :key="index"
        @click="tolink('chat?receiver=' + item.chatUserid)">
        <img src="@/assets/img/logo2.png" width="45" />
        <div class="col q-ml-sm">
          <div class="row">
            <div>{{ abbr(item.chatUserid, 4, 4) }}</div>
            <div class="q-ml-sm">{{ item.shopName }}</div>
          </div>
          <div class="msg">消息：{{ item.msg }}</div>
        </div>
        <div class="badge" v-show="item.count > 0">
          <van-badge :content="item.count"></van-badge>
        </div>
      </div>
    </van-list>

    <van-empty description="暂无消息" v-if="list.length == 0" />
  </div>
</template>

<script>
import { Empty, List, Badge } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Empty.name]: Empty,
    [List.name]: List,
    [Badge.name]: Badge,
  },
  setup() {
    return {
      us: ref(null),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
      list: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.$ws.connection.on("GetMsgList", (val) => {
      // console.log(val)//收到新消息提醒
      this.listUpdateItem(val.chatUid)//获取未读消息
    })
    this.getdata()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    // 加载下一页
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/MsgList/List",
        {
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10,
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 100) {
            let data = res.data.data
            if (data.length == 0) {
              _this.finished = true
            } else {
              _this.finished = false
            }
            _this.list.push(...data)
            console.log('List', data)
          }
        }
      )
    },
    listUpdateItem(chatUid) {
      let _this = this
      _this.$request.post(
        "api/MsgList/ListUpdateItem",
        {
          userid: _this.us.userid,
          chatUid
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            let findIndex = _this.list.findIndex(item => item.chatUid == data.chatUid)
            if (findIndex != -1) {
              _this.list.splice(findIndex, 1)
              _this.list.unshift(data)
            } else {
              _this.list.push(data)
            }
            console.log('ListUpdateItem', data)
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.list {
  padding: 10px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.chat-item {
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
}

.chat-item:last-child {
  margin: 0;
}

.msg {
  margin-top: 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: gray;
  font-size: 13px;
}

.badge {
  margin: 4px;
  padding: 0 6px 10px;
}
</style>